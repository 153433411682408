import { reactive, ref } from 'vue'
type pageTop = {
  url: string
}
type pageModel2 = {
  title: [],
  url: string
}
type pageModel3 = {
  url: string
}
type video1 = {
  demo: string,
  videoUrl: string,
  imgUrl: string
}
type pData = {
  PAGE_MODEL_1: [],
  PAGE_TOP: pageTop,
  PAGE_MODEL_2: pageModel2,
  FUN_ZONE: [],
  PAGE_MODEL_3: pageModel3,
  VIDEO_1: video1
}
const globalData=ref<pData>({} as pData)
export function useData(){
  function setData(data: pData){
    globalData.value=data
  }
  return {
    globalData,
    setData
  }
}
export const store = reactive({
  login: false,
  register: false,
  mobileLogin: false,
  mobileRegister: false,
  mobileSignUp: false,
  isLogin: false,
  aiVisible: false,
  bsVisible: false,
  pageData:{} as pData,
  setPageData(data: pData){
    this.pageData=data
  },
  handleCloseAiModal() {
    this.aiVisible = false;
  },
  handleOpenAiModal() {
    console.log('222')
    this.aiVisible = true;
  },
  handleCloseMobile() {
    this.mobileLogin = false;
    this.mobileRegister = false;
    this.mobileSignUp = false;
  },
  handleOpenMobileRegister() {
    this.mobileRegister = true;
  },
  handleOpenMobileSignUp() {
    this.mobileRegister = false;
    this.mobileLogin = false;
    this.mobileSignUp = true;
  },
  handleOpenMobileLogin() {
    this.mobileLogin = true;
  },
  handleOpenLogin() {
    this.login = true
  },
  handleSetIsLogin() {
    this.isLogin = true
  },
  handleCloseLogin() {
    this.login = false
  },
  handleOpenRegister() {
    this.register = true
  },
  handleCloseRegister() {
    this.register = false
  },
})
